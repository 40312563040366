import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withKeys as _withKeys, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, vShow as _vShow, withDirectives as _withDirectives, withModifiers as _withModifiers, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0d552f49"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "spin"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_back_button = _resolveComponent("ion-back-button")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_searchbar = _resolveComponent("ion-searchbar")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_popover = _resolveComponent("ion-popover")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ion_datetime = _resolveComponent("ion-datetime")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ion_grid = _resolveComponent("ion-grid")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_spinner = _resolveComponent("ion-spinner")!
  const _component_product_card = _resolveComponent("product-card")!
  const _component_ion_infinite_scroll_content = _resolveComponent("ion-infinite-scroll-content")!
  const _component_ion_infinite_scroll = _resolveComponent("ion-infinite-scroll")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_header, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_grid, { fixed: "" }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_toolbar, null, {
                default: _withCtx(() => [
                  ($setup.preSelectCategoryId)
                    ? (_openBlock(), _createBlock(_component_ion_buttons, {
                        key: 0,
                        slot: "start"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_back_button, {
                            class: "back-icon",
                            "default-href": "/home"
                          })
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true),
                  _createVNode(_component_ion_searchbar, {
                    ref: "productSearchBar",
                    class: "product-searchbar",
                    mode: "ios",
                    modelValue: $setup.searchKeyword,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.searchKeyword) = $event)),
                    placeholder: $setup.t('ProductPage.search'),
                    onIonFocus: _cache[1] || (_cache[1] = ($event: any) => ($setup.isSearching = true)),
                    onIonBlur: _cache[2] || (_cache[2] = ($event: any) => ($setup.isSearching = false)),
                    onKeyup: _cache[3] || (_cache[3] = _withKeys((e) => e.target.blur(), ["enter"]))
                  }, null, 8, ["modelValue", "placeholder"]),
                  _createVNode(_component_ion_buttons, { slot: "end" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_button, {
                        color: $setup.filters.sortRuleId != 'default' ? 'primary' : undefined,
                        size: "large",
                        slot: "icon-only",
                        onClick: _cache[4] || (_cache[4] = ($event: any) => ($setup.setPopoverOpen('sortRuleId', true, $event)))
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_icon, { icon: $setup.swapVertical }, null, 8, ["icon"])
                        ]),
                        _: 1
                      }, 8, ["color"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_popover, {
                    isOpen: $setup.popoverState.sortRuleId,
                    event: $setup.popoverEvent,
                    "dismiss-on-select": true,
                    onDidDismiss: _cache[5] || (_cache[5] = ($event: any) => ($setup.setPopoverOpen('sortRuleId', false, $event)))
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_content, null, {
                        default: _withCtx(() => [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.sortRules, (rule) => {
                            return (_openBlock(), _createBlock(_component_ion_item, {
                              lines: "full",
                              key: rule.id,
                              onClick: ($event: any) => ($setup.filters.sortRuleId = rule.id),
                              button: ""
                            }, {
                              default: _withCtx(() => [
                                ($setup.filters.sortRuleId == rule.id)
                                  ? (_openBlock(), _createBlock(_component_ion_icon, {
                                      key: 0,
                                      slot: "start",
                                      icon: $setup.checkmark
                                    }, null, 8, ["icon"]))
                                  : _createCommentVNode("", true),
                                _createVNode(_component_ion_label, null, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString($setup.getLocalizedStr(rule, 'title', 'titleEn')), 1)
                                  ]),
                                  _: 2
                                }, 1024)
                              ]),
                              _: 2
                            }, 1032, ["onClick"]))
                          }), 128))
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }, 8, ["isOpen", "event"])
                ]),
                _: 1
              }),
              _createVNode(_component_ion_row, { class: "filter-items" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_col, {
                    size: "6",
                    "size-lg": "3"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_item, {
                        lines: "none",
                        class: "filter-item",
                        color: $setup.filters.categoryId == 'all' ? 'light' : 'primary',
                        onClick: _cache[7] || (_cache[7] = ($event: any) => ($setup.setPopoverOpen('categoryId', true, $event))),
                        button: ""
                      }, {
                        default: _withCtx(() => [
                          _withDirectives(_createVNode(_component_ion_icon, {
                            slot: "start",
                            icon: $setup.filter
                          }, null, 8, ["icon"]), [
                            [_vShow, $setup.filters.categoryId == 'all']
                          ]),
                          _withDirectives(_createVNode(_component_ion_icon, {
                            slot: "start",
                            icon: $setup.closeCircle,
                            onClick: _cache[6] || (_cache[6] = _withModifiers(($event: any) => ($setup.filters.categoryId = 'all'), ["stop"]))
                          }, null, 8, ["icon"]), [
                            [_vShow, $setup.filters.categoryId != 'all']
                          ]),
                          _createVNode(_component_ion_label, { class: "ion-text-nowrap" }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString($setup.filters.categoryId == 'all' ? $setup.t('category') : $setup.getLocalizedStr($setup.allProductCategories.find(c => c.id == $setup.filters.categoryId), 'title', 'titleEn')), 1)
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }, 8, ["color"]),
                      _createVNode(_component_ion_popover, {
                        isOpen: $setup.popoverState.categoryId,
                        event: $setup.popoverEvent,
                        "dismiss-on-select": true,
                        onDidDismiss: _cache[9] || (_cache[9] = ($event: any) => ($setup.setPopoverOpen('categoryId', false, $event)))
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_content, null, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_item, {
                                lines: "full",
                                button: "",
                                onClick: _cache[8] || (_cache[8] = ($event: any) => ($setup.filters.categoryId = 'all'))
                              }, {
                                default: _withCtx(() => [
                                  ($setup.filters.categoryId == 'all')
                                    ? (_openBlock(), _createBlock(_component_ion_icon, {
                                        key: 0,
                                        slot: "start",
                                        icon: $setup.checkmark
                                      }, null, 8, ["icon"]))
                                    : _createCommentVNode("", true),
                                  _createVNode(_component_ion_label, null, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString($setup.t('all')), 1)
                                    ]),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              }),
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.filteredCategories(), (category) => {
                                return (_openBlock(), _createBlock(_component_ion_item, {
                                  lines: "full",
                                  key: category.id,
                                  onClick: ($event: any) => ($setup.filters.categoryId = category.id),
                                  button: ""
                                }, {
                                  default: _withCtx(() => [
                                    ($setup.filters.categoryId == category.id)
                                      ? (_openBlock(), _createBlock(_component_ion_icon, {
                                          key: 0,
                                          slot: "start",
                                          icon: $setup.checkmark
                                        }, null, 8, ["icon"]))
                                      : _createCommentVNode("", true),
                                    _createVNode(_component_ion_label, null, {
                                      default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString($setup.getLocalizedStr(category, 'title', 'titleEn')), 1)
                                      ]),
                                      _: 2
                                    }, 1024)
                                  ]),
                                  _: 2
                                }, 1032, ["onClick"]))
                              }), 128))
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }, 8, ["isOpen", "event"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_col, {
                    size: "6",
                    "size-lg": "3"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_item, {
                        lines: "none",
                        class: "filter-item",
                        color: $setup.filters.merchantId == 'all' ? 'light' : 'primary',
                        onClick: _cache[11] || (_cache[11] = ($event: any) => ($setup.setPopoverOpen('merchantId', true, $event))),
                        button: ""
                      }, {
                        default: _withCtx(() => [
                          _withDirectives(_createVNode(_component_ion_icon, {
                            slot: "start",
                            icon: $setup.filter
                          }, null, 8, ["icon"]), [
                            [_vShow, $setup.filters.merchantId == 'all']
                          ]),
                          _withDirectives(_createVNode(_component_ion_icon, {
                            slot: "start",
                            icon: $setup.closeCircle,
                            onClick: _cache[10] || (_cache[10] = _withModifiers(($event: any) => ($setup.filters.merchantId = 'all'), ["stop"]))
                          }, null, 8, ["icon"]), [
                            [_vShow, $setup.filters.merchantId != 'all']
                          ]),
                          _createVNode(_component_ion_label, { class: "ion-text-nowrap" }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString($setup.filters.merchantId == 'all' ? $setup.t('brand') : $setup.getLocalizedStr($setup.allMerchants.find(m => m.id == $setup.filters.merchantId), 'name', 'name')), 1)
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }, 8, ["color"]),
                      _createVNode(_component_ion_popover, {
                        isOpen: $setup.popoverState.merchantId,
                        event: $setup.popoverEvent,
                        "dismiss-on-select": true,
                        onDidDismiss: _cache[13] || (_cache[13] = ($event: any) => ($setup.setPopoverOpen('merchantId', false)))
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_content, null, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_item, {
                                lines: "full",
                                button: "",
                                onClick: _cache[12] || (_cache[12] = ($event: any) => ($setup.filters.merchantId = 'all'))
                              }, {
                                default: _withCtx(() => [
                                  ($setup.filters.merchantId == 'all')
                                    ? (_openBlock(), _createBlock(_component_ion_icon, {
                                        key: 0,
                                        slot: "start",
                                        icon: $setup.checkmark
                                      }, null, 8, ["icon"]))
                                    : _createCommentVNode("", true),
                                  _createVNode(_component_ion_label, null, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString($setup.t('all')), 1)
                                    ]),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              }),
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.filteredMerchants(), (m) => {
                                return (_openBlock(), _createBlock(_component_ion_item, {
                                  lines: "full",
                                  key: m.id,
                                  onClick: ($event: any) => ($setup.filters.merchantId = m.id),
                                  button: ""
                                }, {
                                  default: _withCtx(() => [
                                    ($setup.filters.merchantId == m.id)
                                      ? (_openBlock(), _createBlock(_component_ion_icon, {
                                          key: 0,
                                          slot: "start",
                                          icon: $setup.checkmark
                                        }, null, 8, ["icon"]))
                                      : _createCommentVNode("", true),
                                    _createVNode(_component_ion_label, null, {
                                      default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(m.name), 1)
                                      ]),
                                      _: 2
                                    }, 1024)
                                  ]),
                                  _: 2
                                }, 1032, ["onClick"]))
                              }), 128))
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }, 8, ["isOpen", "event"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_col, {
                    size: "6",
                    "size-lg": "3"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_item, {
                        lines: "none",
                        class: "filter-item",
                        color: $setup.filters.priceRange == 'all' ? 'light' : 'primary',
                        onClick: _cache[15] || (_cache[15] = ($event: any) => ($setup.setPopoverOpen('priceRange', true, $event))),
                        button: ""
                      }, {
                        default: _withCtx(() => [
                          _withDirectives(_createVNode(_component_ion_icon, {
                            slot: "start",
                            icon: $setup.logoUsd
                          }, null, 8, ["icon"]), [
                            [_vShow, $setup.filters.priceRange == 'all']
                          ]),
                          _withDirectives(_createVNode(_component_ion_icon, {
                            slot: "start",
                            icon: $setup.closeCircle,
                            onClick: _cache[14] || (_cache[14] = _withModifiers(($event: any) => ($setup.filters.priceRange = 'all'), ["stop"]))
                          }, null, 8, ["icon"]), [
                            [_vShow, $setup.filters.priceRange != 'all']
                          ]),
                          _createVNode(_component_ion_label, { class: "ion-text-nowrap" }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString($setup.filters.priceRange == 'all' ? $setup.t('priceRange') : $setup.filters.priceRange), 1)
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }, 8, ["color"]),
                      _createVNode(_component_ion_popover, {
                        isOpen: $setup.popoverState.priceRange,
                        event: $setup.popoverEvent,
                        "dismiss-on-select": true,
                        onDidDismiss: _cache[17] || (_cache[17] = ($event: any) => ($setup.setPopoverOpen('priceRange', false)))
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_content, null, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_item, {
                                lines: "full",
                                button: "",
                                onClick: _cache[16] || (_cache[16] = ($event: any) => ($setup.filters.priceRange = 'all'))
                              }, {
                                default: _withCtx(() => [
                                  ($setup.filters.priceRange == 'all')
                                    ? (_openBlock(), _createBlock(_component_ion_icon, {
                                        key: 0,
                                        slot: "start",
                                        icon: $setup.checkmark
                                      }, null, 8, ["icon"]))
                                    : _createCommentVNode("", true),
                                  _createVNode(_component_ion_label, null, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString($setup.t('all')), 1)
                                    ]),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              }),
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.filteredPriceRange(), (range) => {
                                return (_openBlock(), _createBlock(_component_ion_item, {
                                  lines: "full",
                                  key: range.value,
                                  onClick: ($event: any) => ($setup.filters.priceRange = range.value),
                                  button: ""
                                }, {
                                  default: _withCtx(() => [
                                    ($setup.filters.priceRange == range.value)
                                      ? (_openBlock(), _createBlock(_component_ion_icon, {
                                          key: 0,
                                          slot: "start",
                                          icon: $setup.checkmark
                                        }, null, 8, ["icon"]))
                                      : _createCommentVNode("", true),
                                    _createVNode(_component_ion_label, null, {
                                      default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(range.value), 1)
                                      ]),
                                      _: 2
                                    }, 1024)
                                  ]),
                                  _: 2
                                }, 1032, ["onClick"]))
                              }), 128))
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }, 8, ["isOpen", "event"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_col, {
                    size: "6",
                    "size-lg": "3"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_item, {
                        lines: "none",
                        class: "filter-item",
                        color: !$setup.filters.pickupDate ? 'light' : 'primary',
                        onClick: _cache[19] || (_cache[19] = ($event: any) => ($setup.setPopoverOpen('pickupDate', true, $event))),
                        button: ""
                      }, {
                        default: _withCtx(() => [
                          _withDirectives(_createVNode(_component_ion_icon, {
                            slot: "start",
                            icon: $setup.calendarOutline
                          }, null, 8, ["icon"]), [
                            [_vShow, !$setup.filters.pickupDate]
                          ]),
                          _withDirectives(_createVNode(_component_ion_icon, {
                            slot: "start",
                            icon: $setup.closeCircle,
                            onClick: _cache[18] || (_cache[18] = _withModifiers(($event: any) => ($setup.filters.pickupDate = null), ["stop"]))
                          }, null, 8, ["icon"]), [
                            [_vShow, $setup.filters.pickupDate]
                          ]),
                          _createVNode(_component_ion_label, null, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(!$setup.filters.pickupDate ? $setup.t('CartItemModal.pickupDate') : $setup.formatDateString($setup.filters.pickupDate)), 1)
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }, 8, ["color"]),
                      _createVNode(_component_ion_popover, {
                        isOpen: $setup.popoverState.pickupDate,
                        event: $setup.popoverEvent,
                        "keep-contents-mounted": true,
                        "dismiss-on-select": true,
                        onDidDismiss: _cache[21] || (_cache[21] = ($event: any) => ($setup.setPopoverOpen('pickupDate', false))),
                        style: {"--width":"300px"}
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_datetime, {
                            id: "datetime",
                            presentation: "date",
                            modelValue: $setup.filters.pickupDate,
                            "onUpdate:modelValue": _cache[20] || (_cache[20] = ($event: any) => (($setup.filters.pickupDate) = $event)),
                            showDefaultButtons: false,
                            min: $setup.formatDateString(new Date(), 'YYYY-MM-DD')
                          }, null, 8, ["modelValue", "min"])
                        ]),
                        _: 1
                      }, 8, ["isOpen", "event"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_ion_content, {
        onIonScroll: $setup.handleScroll,
        scrollEvents: true
      }, {
        default: _withCtx(() => [
          _withDirectives(_createVNode(_component_ion_grid, { fixed: "" }, {
            default: _withCtx(() => [
              ($setup.loading)
                ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                    _createVNode(_component_ion_spinner)
                  ]))
                : (_openBlock(), _createBlock(_component_ion_row, { key: 1 }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.filteredProducts().slice(0, $setup.numOfVisibleItems), (product) => {
                        return (_openBlock(), _createBlock(_component_ion_col, {
                          "size-xs": "6",
                          "size-md": "4",
                          "size-xl": "3",
                          key: product.id
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_product_card, {
                              product: product,
                              hideMerchantName: false,
                              userLoggedIn: $setup.userLoggedIn,
                              showActionButtons: false
                            }, null, 8, ["product", "userLoggedIn"])
                          ]),
                          _: 2
                        }, 1024))
                      }), 128))
                    ]),
                    _: 1
                  })),
              _createVNode(_component_ion_infinite_scroll, {
                onIonInfinite: _cache[22] || (_cache[22] = ($event: any) => ($setup.loadData($event))),
                threshold: "100px",
                id: "infinite-scroll"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_infinite_scroll_content, {
                    "loading-spinner": "bubbles",
                    "loading-text": $setup.t('loadingMore')
                  }, null, 8, ["loading-text"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 512), [
            [_vShow, !$setup.isSearching || $setup.searchKeyword]
          ])
        ]),
        _: 1
      }, 8, ["onIonScroll"])
    ]),
    _: 1
  }))
}