
// Vue reactivity
import { computed, onMounted, reactive, ref, watch } from "vue";

// icons
import { search, searchOutline, notificationsOutline, checkmark,
        closeCircle, filter, logoUsd, calendarOutline, swapVertical, } from 'ionicons/icons';

// components
import { IonPage, IonHeader, IonContent, IonGrid, IonToolbar,
        IonSearchbar, IonButtons, IonButton, IonBackButton, IonSpinner,
        IonRow, IonCol, IonPopover, IonItem, IonLabel, IonIcon,
        IonInfiniteScroll, IonInfiniteScrollContent, IonDatetime, IonDatetimeButton, IonModal,
        onIonViewDidEnter, } from "@ionic/vue";
import ProductCard from '@/components/product/ProductCard.vue';

// composables
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import { useRoute, useRouter, } from 'vue-router';
import { utils } from '@/composables/utils';

export default {
  name: "ProductListPage",
  components: {
    IonPage, IonHeader, IonContent, IonGrid, IonToolbar,
    IonSearchbar, IonButtons, IonButton, IonBackButton, IonSpinner,
    IonRow, IonCol, IonPopover, IonItem, IonLabel, IonIcon,
    IonInfiniteScroll, IonInfiniteScrollContent, IonDatetime, IonDatetimeButton, IonModal,
    ProductCard,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const { t } = useI18n();
    const { getLocalizedStr, infiniteScrollLoadData, formatDateString, dateDiffInDays, } = utils();

    // route params
    const { preSelectCategoryId, triggerSearch, } = route.params;

    // 1. declare state variables (ref to make them reactive)
    const userLoggedIn = computed(() => store.state.loggedIn);
    const loading = computed(() => store.state.loadingAppPublicData);
    const allProducts = computed(() => store.state.allProducts);
    const allProductCategories = computed(() => store.state.allProductCategories);
    const allMerchants = computed(() => store.state.allMerchants);
    const searchKeyword = ref("");
    const productSearchBar = ref(null);
    const isSearching = ref(false);

    const sortRules = [
      { id: "default", title: '預設', titleEn: 'Default' },
      { id: "priceLowtoHigh", title: '價錢 (低至高)', titleEn: 'Price (Low to High)' },
      { id: "priceHighToLow", title: '價錢 (高至低)', titleEn: 'Price (High to Low)' },
      { id: "prepDaysLowToHigh", title: '最早取貨日期 (近至遠)', titleEn: 'Pick up date (Closest to Farthest)' },
      //{ id: "prepDaysHighToLow", title: '價錢 (高至低)', titleEn: 'Price (low to high)' },
    ];
    const priceRanges = [
      { value: "< HK$20", min: 0, max: 20 },
      { value: "HK$20-50", min: 20, max: 50 },
      { value: "HK$50-100", min: 50, max: 100 },
      { value: "HK$100-200", min: 100, max: 200 },
      { value: "> HK$200", min: 200, max: Infinity },
    ];
    const filters = reactive({
      categoryId: 'all',
      priceRange: 'all',
      merchantId: 'all',
      pickupDate: null,
      sortRuleId: 'default',
    });
    const popoverState = reactive({
      categoryId: false,
      priceRange: false,
      merchantId: false,
      pickupDate: false,
      sortRuleId: false,
    })
    const popoverEvent = ref();
    const setPopoverOpen = (popoverKey: any, state: boolean, ev: any) => {
      popoverEvent.value = ev; 
      popoverState[popoverKey] = state;
    };

    // Scroll
    const isScrolling = ref(false);
    let scrollTimeout;
    const handleScroll = () => {
      isScrolling.value = true;
      clearTimeout(scrollTimeout);
      scrollTimeout = setTimeout(() => {
        isScrolling.value = false;
      }, 250);
    };

    const checkMatchKeyword = (obj: any, keyword: any) => {
      return `${obj.title.toLowerCase()} ${obj.merchantName.toLowerCase()}`.includes(keyword);
    }
    const filteredProducts = (checkPriceRange = true) => {
      const { categoryId: catId, merchantId: merId } = filters;
      let res = allProducts.value.filter((obj: any) => {
        const { minPrepDays, categoryId, merchantId, } = obj;
        if (filters.pickupDate) {
          if (isNaN(parseInt(minPrepDays))) return false; // no prep day info available
          const diff = dateDiffInDays(formatDateString(filters.pickupDate, 'YYYY-MM-DD'), new Date());
          return diff >= parseInt(minPrepDays);
        }
        return (catId == 'all' || catId == categoryId) && (merId == 'all' || merId == merchantId) && checkMatchKeyword(obj, searchKeyword.value);
      });
      if (checkPriceRange && filters.priceRange != 'all') {
        const range = priceRanges.find(r => r.value == filters.priceRange);
        res = res.filter(p => (p.price >= range.min && p.price < range.max)).sort((a,b) => a.price - b.price);
      }
      if (filters.sortRuleId != 'default') {
        res.sort((a, b) => {
          const priceA = Number(a.price), priceB = Number(b.price);
          if (filters.sortRuleId == 'priceHighToLow') return priceA < priceB ? 1 : -1;
          if (filters.sortRuleId == 'prepDaysLowToHigh') return a.minPrepDays == '' ? 1 : (Number(a.minPrepDays) < Number(b.minPrepDays) ? -1 : 1);
          return priceA < priceB ? -1 : 1; // normally is priceLowtoHigh
        });
      }
      res = [
        ...res.filter(p => p.inStock == true),
        ...res.filter(p => p.inStock == false),
      ];
      return res;
    }
    const numOfVisibleItems = ref(20);
    const loadData = (ev) => {
      infiniteScrollLoadData(ev, numOfVisibleItems, filteredProducts());
    }

    // Lifecycle / watch
    onIonViewDidEnter(() => {
      if (triggerSearch == '1') {
        setTimeout(() => { productSearchBar.value.$el.setFocus() }, 150); // redirect from home page search button
      }
      if (preSelectCategoryId) filters.categoryId = preSelectCategoryId.toString(); // first enter
    })
    watch(() => ({...filters}), (curr, prev) => {
      //if (curr.categoryId != prev.categoryId) filters.priceRange = 'all'; // category changed
      numOfVisibleItems.value = 20;
    })

    // 3. return variables & methods to be used in template HTML
    return {
      // icons
      search, searchOutline, notificationsOutline, checkmark,
      closeCircle, filter, logoUsd, calendarOutline,
      swapVertical,
      
      // state variables
      loading, userLoggedIn,
      allProductCategories, allMerchants,
      productSearchBar, searchKeyword, isSearching,
      preSelectCategoryId,

      // methods
      t, getLocalizedStr,
      formatDateString,

      // product filters
      filters, priceRanges, sortRules,
      filteredCategories: () => {
        const res = filters.categoryId != 'all' ? allProducts.value : filteredProducts();
        return allProductCategories.value.filter(c => res.find(p => p.categoryId == c.id)).sort((a, b) => {
          const titleA = getLocalizedStr(a, 'title', 'titleEn');
          const titleB = getLocalizedStr(b, 'title', 'titleEn');
          return titleA?.toLowerCase() < titleB?.toLowerCase() ? -1 : 1;
        });
      },
      filteredMerchants: () => {
        const res = filters.merchantId != 'all' ? allProducts.value : filteredProducts();
        return allMerchants.value.filter(m => res.find(p => p.merchantId == m.id)).sort((a, b) => {
          const titleA = getLocalizedStr(a, 'name', 'name');
          const titleB = getLocalizedStr(b, 'name', 'name');
          return titleA?.toLowerCase() < titleB?.toLowerCase() ? -1 : 1;
        });
      },
      filteredPriceRange: () => (priceRanges.filter(r => (filteredProducts(false).some(p => p.price >= r.min && p.price < r.max)))),

      // Infinite load
      loadData, numOfVisibleItems, filteredProducts,

      // popover
      popoverState, popoverEvent,
      setPopoverOpen,

      // Scroll
      handleScroll, isScrolling,
    };
  },
};
